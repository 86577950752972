<div class="container">
  <div class="row">
    <div class="col-md screen" *ngIf="!msal.instance.getAllAccounts().length">
      <h1 class="title">Welkom, log hier in.</h1>
      <button (click)="getSession().login()" mat-raised-button>Login <mat-icon>open_in_new</mat-icon></button>
    </div>
    <div class="col-md screen" *ngIf="msal.instance.getAllAccounts().length && msal.instance.getActiveAccount()?.idTokenClaims?.extension_vitknip_groups === ''">
      <h3 class="title">Het lijkt erop dat u geen actieve rollen heeft.</h3>
    </div>
    <div *ngIf="msal.instance.getAllAccounts().length" class="my-5">
      <label class="title">Welkom bij het dashboard van de Vitaliteitsknip!</label>

      <div class="row" *ngIf="getSession().hasRole(3)">
        <div class="col-md-3">
          <h3>Medewerkers</h3>
          <button mat-raised-button routerLink="/employees/existing">Bestaand</button>
          <!-- <button>Toevoegen</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
