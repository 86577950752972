export const APP_CONFIG = {
  APPLICATION_NAME: "Dashboard Vitaliteitsknip Heerlen",
  production: true,
  environment: "PROD",
  TEAMS_API: "https://www.google.com/",
  API: "https://api.iota.heerlen.vitaliteitsknip.nl/",
  HASURA_ADMIN_KEY: null,
  TIMER_TICK_RATE: 100,
  JWT: null,
  MSAL: {
    CLIENT_ID: "60be37f9-ebb0-46c2-aa5d-55b72953e23e",
    AUTHORITY: "https://heerlenvitknip.b2clogin.com/heerlenvitknip.onmicrosoft.com/B2C_1_Signin",
    KNOWN_AUTHORITY: "https://heerlenvitknip.b2clogin.com/",
  },
};
